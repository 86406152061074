.main-section {
  min-height: calc(100vh - 70px - 66px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.layout-header {
  position: relative;
  display: inline-block;
}
.header-btn {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  padding-right: 10px;
}
.barsMenu {
  width: fit-content;
  height: 46px;
}

.logo img {
  height: 30px;
}
.header {
	padding: 0;
	margin: 0;
	height: 60px;
	background-color: #00acee;
	box-sizing: border-box;
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.header ul.header-ul {
	margin: 0 30px;
	height: 100%;
	padding: 0;
	display: flex;
	flex-direction: row;
	list-style: none;
}

li.header-li {
	font-size: 1.5em;
	margin: 5px;
	text-align: center;
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

li.header-li a {
	padding: 10px 10px;
	color: #fff;
	height: 100%;
	text-align: center;
	/* line-height: 50px; */
	display: flex;
	align-items: center;
	box-sizing: border-box;
}

li.header-li a:hover {
	/* padding: 10px 10px; */
	color: #fff;
	background-color: #61dbfb;
	border: 1px solid #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
